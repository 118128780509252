<template>
  <div>
    <b-card class="mb-2">
       <div class="d-flex justify-content-between">
        <h1 class="my-auto">Liste des Types d'Établissements</h1>
        <b-button
          variant="primary"
          v-b-modal.modal-center
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Ajouter un role</span>
        </b-button>
        
      </div>
    </b-card>
    <b-card > 
      <b-row>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Roles</th>
              <th scope="col">Permissions (Objet; Action)</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(role, keyRole) in tableData" v-if="tableData && tableData.length > 1">
              <th scope="row">
                <strong>{{ keyRole + 1 }}</strong>
              </th>
              <td>{{role.nom}}</td>
              <td >
                <span v-for="(permission, keyPermission) in role.permissions">
                  <b-badge pill class="mr-1">{{permission.subject+'_'+permission.action}}</b-badge>
                </span>
              </td>
              <td>
                <b-button-group>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-25"
                    />
                    Modifier
                  </b-button>
                  <!-- <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-25"
                    />
                    Supprimer
                  </b-button> -->
                </b-button-group>
              </td>
            </tr>
            <tr v-else>
              Aucune donnée trouvée
            </tr>
          </tbody>
        </table>
      </b-row>
      
      <div class="d-flex justify-content-between mt-1" v-if="tableData && tableData.length > 1">
        <div class="d-flex flex-row"> 
          <span class="my-auto mr-1">Afficher </span>
          <div>
            <b-form-select
              v-model="paginationData.perPage"
              :options="optionsPerPage"
              size="sm"
              class="form-control col"
            />
          </div>
          <span class="my-auto ml-1">lignes </span>
        </div>

        <div>
          <b-pagination
            v-model="paginationData.currentPage"
            :total-rows="paginationData.total"
            :per-page="paginationData.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>

        <div class="my-auto">
          <!-- <div class="d-flex flex-row">  -->
            <span class="my-auto">Affichage de {{paginationData.metaData.from}} à {{paginationData.metaData.to}} sur {{paginationData.total}} </span>
          <!-- </div> -->
        </div>
      </div>
    </b-card>


    <!-- Info modal -->
    <b-modal
      id="modal-center"
      centered
      title="Nouveau Role"
      ok-only
      ok-title="Valider"
      cancel-title="Fermer"
      cancel-variant="outline-secondary"
      size="lg"
      @ok="saveNewPermission()"

    >
      <b-card-text >
        <!-- <div class="mb-3">{{newRole}}</div> -->
        <!-- <div class=" p-1"> -->
          <div class="row col-12">
            <label for="" class="col-3 my-auto"><strong>Nom</strong></label>
            <b-form-input     
              v-model="newRole.nom"       
              type="text"
              class="form-control col-9"
              placeholder="Nom du role"
            />
          </div>
          <div class="row col-12 mt-1">
            <label for="" class="col-3 my-auto"><strong>Permissions</strong></label>
            <b-input-group class="col" >
              <div class="col-11 row mb-1" v-for="(permission, keyPermission) in newRole.permissions">
                <b-form-input
                  v-model="permission.subject"
                  type="text"
                  class="form-control col"
                  placeholder="Object"
                />
                <b-form-select
                  v-model="permission.action"
                  :options="permissionsActions"
                  class="form-control col"
                  placeholder="Action autorisée"
                />
                <!-- <b-form-input            
                  v-model="permission.action"
                  type="text"
                  class="form-control col"
                  placeholder="Action autorisée"
                /> -->
                <b-input-group-append is-text @click="removePermissions(keyPermission)">
                  <feather-icon
                    v-b-tooltip.hover.bottom="'Effacer la permisison!'"
                    icon="XCircleIcon"
                    class="cursor-pointer col"
                  />
                </b-input-group-append>
              </div>
              <div class="col-1">
                <b-button @click="addPermissions()"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  <feather-icon
                    v-b-tooltip.hover.bottom="'Ajouter une permission!'"
                    icon="PlusIcon"
                    class="cursor-pointer"
                  />
                </b-button>
              </div>
              
            </b-input-group>
            
          </div>
        <!-- </div> -->
        
      </b-card-text>
    </b-modal>

  </div>
</template>
  

<script>
import {
  BTable, BCard, BBadge, BRow, BCol, BFormGroup, BFormSelect,
  BPagination, BInputGroup, BFormInput, BInputGroupAppend, 
  BButton, BSpinner, BButtonGroup, VBModal, BModal, VBTooltip,
  BCardText,

} from 'bootstrap-vue'
import TypeEtablissementCreate from './TypeEtablissementCreate'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


// store module and vuex utilities
import permissionsStroreModule from "@/store/permissions";
import rolesStoreModule from "@/store/roles";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "permissions", module: permissionsStroreModule },
      { path: "roles", module: rolesStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules};
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  components: {
    BTable,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BButtonGroup,
    TypeEtablissementCreate,
    VBModal, BModal,
    BCardText,
  },
  watch: {
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(this.tableData.slice(0, val - 1))
        }
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.loadData()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.loadData()
      },
    },
    $route: {
      immediate: true,
      async handler(val, old) {
        this.loadData()
      },
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoadingTableData: false,
      tableData: null,
      tableColumns: [
        // { key: 'id', label: '#', sortable: false },
        { key: 'nom', label: 'Role',sortable: true, sortDirection: 'asc' },
        { key: 'permissions', label: 'Permissions', },
        { key: 'actions', label: 'Actions' }
      ],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      newRole: {
        nom:'',
        permissions: [
          {subject: '', action: ''}
        ]
      },
      permissionsActions: ['create', 'read', 'update', 'delete', 'access' ],

      items: [
        { isActive: true, age: 40, name: { first: 'Dickerson', last: 'Macdonald' } },
        { isActive: false, age: 21, name: { first: 'Larsen', last: 'Shaw' } },
        {
          isActive: false,
          age: 9,
          name: { first: 'Mini', last: 'Navarro' },
          _rowVariant: 'success'
        },
        { isActive: false, age: 89, name: { first: 'Geneva', last: 'Wilson' } },
        { isActive: true, age: 38, name: { first: 'Jami', last: 'Carney' } },
        { isActive: false, age: 27, name: { first: 'Essie', last: 'Dunlap' } },
        { isActive: true, age: 40, name: { first: 'Thor', last: 'Macdonald' } },
        {
          isActive: true,
          age: 87,
          name: { first: 'Larsen', last: 'Shaw' },
          _cellVariants: { age: 'danger', isActive: 'warning' }
        },
        { isActive: false, age: 26, name: { first: 'Mitzi', last: 'Navarro' } },
        { isActive: false, age: 22, name: { first: 'Genevieve', last: 'Wilson' } },
        { isActive: true, age: 38, name: { first: 'John', last: 'Carney' } },
        { isActive: false, age: 29, name: { first: 'Dick', last: 'Dunlap' } }
      ],
      fields: [
        { key: 'nom', label: 'Nom', sortable: true, sortDirection: 'desc' },
        { key: 'age', label: 'Person age', sortable: true, class: 'text-center' },
        {
          key: 'isActive',
          label: 'Is Active',
          formatter: (value, key, item) => {
            return value ? 'Yes' : 'No'
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
        { key: 'actions', label: 'Actions' }
      ],
      totalRows: 1,
      currentPage: 1,
      // perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      }
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },

  mounted() {
    // Set the initial number of items
  },
  methods: {
    ...mapActions('permissions', {
      fetchPermissions: 'fetchPermissions',
    }),
    ...mapActions('roles', {
      fetchRoles: 'fetchRoles',
    }),
    oh(){
      console.log('oh: ', this.paginationData)
    },
    loadData(){
      this.isLoadingTableData = true 
      const currentParams = {
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      }
      console.log('params: ', currentParams)
      this.fetchRoles({params: currentParams || null,})
        .then(res => {
          this.isLoadingTableData = false
          this.tableData = res.data.roles
          this.paginationData.total = res.data.total

          this.paginationData.metaData.from = (this.paginationData.perPage * this.paginationData.currentPage) - (this.paginationData.perPage)
          if(this.paginationData.metaData.from == 0) this.paginationData.metaData.from += 1          
          this.paginationData.metaData.to = (this.paginationData.perPage * this.paginationData.currentPage) - this.paginationData.perPage + this.tableData.length
        })
        .catch(err => {
          this.isLoadingTableData = false
          console.log(err)
        })
      // this.fetchPermissions()
      //   .then(res => {
      //     console.log(res)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    },
    addPermissions(){
      this.newRole.permissions.push(
        {subject: '', action: ''}
      )
    },
    removePermissions(index){
      if(this.newRole.permissions.length > 1)
        this.newRole.permissions.splice(index, 1)
      else{
         this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'La liste des permissions ne peut être vide',
              // icon: 'XCircleIcon',
              variant: 'danger',
              // text: 'Bienvenue !',
            },
          })
      }
    },
    saveNewPermission(){
      console.log('this.newRole: ', this.newRole)
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>
